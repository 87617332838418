<template>
  <v-card>
    <v-row no-gutters>
      <v-col class="scroller" :style="scrollerStyle">
        <v-card class="card">
          <v-card-text>
            <h2>Antagelser om scenarier og spænd</h2>
            <v-row>
              <v-col>
                <TcoCalculation
                  :table="getTable(2)"
                  :headers="getHeaders(1)"
                  :hoveredTag="hoveredTag"
                />
              </v-col>
              <v-col cols="auto">
                <TagInterval tag="A" :hoveredTag="hoveredTag" :start="1" :end="4" />
              </v-col>
            </v-row>
            <br />
            <TcoCalculation :table="getTable(1)" :headers="getHeaders(0)" :hoveredTag="hoveredTag" />
          </v-card-text>
        </v-card>
        <v-card class="card">
          <v-card-text>
            <h2>
              Beregnet omkostning (forventet volumen sammenholdt med tilbudt
              priser)
            </h2>
            <TcoCalculationSummaryTable :questions="questions" :showTags="true" :hoveredTag="hoveredTag" />
          </v-card-text>
        </v-card>
        <v-card class="card">
          <v-card-text>
            <h2>Evalueringsteknisk pris (beregning)</h2>
            <v-tabs v-model="tab" color="accent" bg-color="transparent">
              <v-tab class="bg-canvas">Scenarie 1</v-tab>
              <v-tab class="bg-canvas">Scenarie 2</v-tab>
              <v-tab class="bg-canvas">Scenarie 3</v-tab>
            </v-tabs>
            <v-window v-model="tab" class="mt-4">
              <v-window-item class="bg-canvas">
                <TcoCalculation
                  :table="getTable(3)"
                  :headers="getHeaders(2)"
                  :hoveredTag="hoveredTag"
                />
                <br />
                <TcoCalculation
                  :table="getTable(4)"
                  :headers="getHeaders(3)"
                  :hoveredTag="hoveredTag"
                />
              </v-window-item>
              <v-window-item class="bg-canvas">
                <TcoCalculation
                  :table="getTable(5)"
                  :headers="getHeaders(2)"
                  :hoveredTag="hoveredTag"
                />
                <br />
                <TcoCalculation
                  :table="getTable(6)"
                  :headers="getHeaders(3)"
                  :hoveredTag="hoveredTag"
                />
              </v-window-item>
              <v-window-item class="bg-canvas">
                <TcoCalculation
                  :table="getTable(7)"
                  :headers="getHeaders(2)"
                  :hoveredTag="hoveredTag"
                />
                <br />
                <TcoCalculation
                  :table="getTable(8)"
                  :headers="getHeaders(3)"
                  :hoveredTag="hoveredTag"
                />
              </v-window-item>
            </v-window>

            <h3 class="pb-2">Samlet beregning</h3>
            <TcoCalculation
              :table="getTable(9)"
              :headers="getHeaders(4)"
              :hoveredTag="hoveredTag"
              :tags="[
                { row: 0, cell: 0, value: 'K' },
                { row: 1, cell: 0, value: 'F' },
                { row: 3, cell: 0, value: 'G' },
                { row: 4, cell: 3, value: 'B' },
              ]"
            />
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="3">
        <v-card color="#fff9ef" class="fill-height" flat>
          <v-card-text ref="description">
            <TcoCalculationDescription @updateHoveredTag="updateHoveredTag" />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-card>
</template>

<script setup lang="ts">
import { ref, onMounted, nextTick } from 'vue';
import { DeepQuestion, TcoCalculationTable, TagInterval } from '@dims/components';
import TcoCalculationDescription from './TcoCalculationDescription.vue';
import TcoCalculationSummaryTable from './TcoCalculationSummaryTable.vue';
import TcoCalculation from './TcoCalculation.vue';

const { tables } = defineProps<{
  questions: DeepQuestion[],
  tables: Record<string, TcoCalculationTable> }>();
const scrollerStyle = ref({ maxHeight: undefined as string | undefined });
const tab = ref(0);
const hoveredTag = ref<string>();

function updateHoveredTag(tag: string) {
  hoveredTag.value = tag;
}

const description = ref<Element>();

onMounted(() => {
  // Set height of left column to height of description
  // We wait for next tick because mounted() does not guarantee that the DOM has finished rendering
  void nextTick(() => {
    if (description.value) {
      const height = description.value.clientHeight;
      scrollerStyle.value.maxHeight = `${height}px`;
    }
  });
});

const headers = [
  [
    {
      value: '',
      tag: '',
    },
    {
      value: 'Min. (spænd)',
      tag: 'J',
    },
    {
      value: 'Max. (spænd)',
      tag: 'J',
    },
    {
      value: 'Tilbudt rabat (eksempel)',
      tag: 'K',
    },
  ],
  [
    {
      value: '',
      tag: '',
    },
    {
      value: 'Vægt',
      tag: 'G',
    },
    {
      value: 'Antaget andel af forventede volumen',
      tag: 'I',
    },
    {
      value: 'Beregnet omkostning',
      tag: 'H',
    },
  ],
  [
    {
      value: 'Beregnet omkostning (uden rabat)',
      tag: 'H',
    },
  ],
  [
    {
      value: '',
      tag: '',
    },
    {
      value: 'Start (spænd)',
      tag: 'J',
    },
    {
      value: 'Slut (spænd)',
      tag: 'J',
    },
    {
      value: 'Beregnet omkostning (inklusiv fratrukket rabat)',
      tag: 'K',
    },
  ],
  [
    {
      value: '',
      tag: '',
    },
    {
      value: 'Scenarie 1',
      tag: '',
    },
    {
      value: 'Scenarie 2',
      tag: '',
    },
    {
      value: 'Scenarie 3',
      tag: '',
    },
  ],
];

function getTable(idx: number) {
  const table = Object.values(tables)[idx];
  if (!table) {
    throw new Error();
  }
  return table;
}

function getHeaders(idx: number) {
  const header = headers[idx];
  if (!header) {
    throw new Error();
  }
  return header;
}

</script>
<style scoped>
.card {
  margin: 2em;
  background: rgb(var(--v-theme-canvas)) !important;
}

h2 {
  color: rgb(var(--v-theme-primary));
  margin-bottom: 1em;
  margin-top: 0;
  text-transform: uppercase;
}

.scroller {
  overflow-y: scroll !important;
}
</style>
