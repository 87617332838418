<template>
  <EvaluationOfferListBase
    :tender="tender"
    :offers="offers"
    :offersEvaluations="offersEvaluations"
    :additionalHeaders="[]"
    :isLoadingOffers="isLoadingOffers"
    contractSumTooltip="Den samlet tilbudte pris inkl. optioner"
    @fetchOffers="fetchOffers"
  >
    <template #additional-columns></template>
    <template #tco></template>
    <template #bpq="{ offer, conditionalOffers, index }">
      <BpqDocumentationDialog
        :offer="offer"
        :offerEvaluation="getOfferEvaluation(offer.id)"
        :tender="tender"
        :index="index"
        :isOfferConditional="conditionalOffers.includes(offer)"
      />
    </template>
  </EvaluationOfferListBase>
</template>

<script setup lang="ts">
import { Offer, Tender, EvaluationOfferListBase, OfferEvaluation } from '@dims/components';
import BpqDocumentationDialog from './BpqDocumentationDialog.vue';

const emit = defineEmits<{
  fetchOffers: []
}>();
const { offersEvaluations, isLoadingOffers = false } = defineProps<{
  tender: Tender,
  offers: Offer[],
  offersEvaluations: OfferEvaluation[],
  isLoadingOffers?: boolean
}>();

function fetchOffers() {
  emit('fetchOffers');
}

function getOfferEvaluation(offerId: string) {
  return offersEvaluations.find((oe) => oe.offerId === offerId);
}

</script>
