<template>
  <CreateTenderDialog
    v-if="store.myTendersFetched"
    :tender="tender"
    :tender-stub="tenderStub"
    :create-button-disabled="!isDraftValid()"
    :createAsTemplate="createAsTemplate"
  >
    <template #create-tender-wizard-step>
      <CreateTenderWizardStep :createAsTemplate="createAsTemplate"></CreateTenderWizardStep>
    </template>
  </CreateTenderDialog>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { Tender, useStore } from '@dims/components';
import CreateTenderWizardStep from '@/components/create-tender/CreateTenderWizardStep.vue';
import { useStore0222 } from '@/store/store0222';
import { tenderStub as defaultTenderStub } from '@/services';

const { tender = null, createAsTemplate = false } = defineProps<{
  /** Only when the create/clone button is on an existing tender */
  tender?: Tender | null,
  createAsTemplate?: boolean,
}>();
const store = useStore();
const store0222 = useStore0222();

function isDraftValid() {
  return Boolean(store0222.draftTender?.description);
}

const tenderStub = computed(() => ({ ...defaultTenderStub, agreementName: store.agreementId }));

</script>
