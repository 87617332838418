<template>
  <div>
    <LoadingSpinner
      :visible="isLoading"
      loadingMessage="Henter uddrag af krav"
    />
    <div v-if="!isLoading">
      <v-card
        v-if="questionnaire && tender"
        class="pa-3 scroll"
        color="#fff9ef"
      >
        <div class="header">
          <div class="float-right">
            <v-btn
              variant="text"
              @click="downloadPdf()"
            >Download som PDF<v-icon end>mdi-download</v-icon></v-btn>
            <LoadingSpinner
              :visible="isDownloading"
              loadingMessage="Danner PDF..."
            />
            <div class="d-flex align-center text-accent">
              <v-btn
                @click="expandingPanel(false)"
                variant="text"
                color="accent"
              >Fold ind</v-btn>|
              <v-btn
                @click="expandingPanel(true)"
                variant="text"
                color="accent"
              >Fold ud</v-btn>
            </div>
          </div>
          <div>{{ tender.user.companyName }}</div>
          <div>{{ tender.description }}</div>
          <div>{{ tender.friendlyId }}</div>
        </div>
        <v-card-title class="text-primary">
          <span class="title-text">Uddrag af leveringskontraktens bilag</span>
        </v-card-title>
        <v-card-subtitle class="mb-n4">
          Samtlige krav fremgår af ‘Offentliggjort materiale’ på fanen ‘Overblik’.
        </v-card-subtitle>
        <v-card-text>
          <div v-for="tab in specificationTabs" :key="tab.questionId">
            <h2>{{ tab.title }}</h2>
            <br />
            <div
              v-for="section in tab.questions || []"
              :key="section.questionId"
            >
              <ReadOnlyTemplateQuestion
                :section="section"
                :question="section"
                :tender="tender"
                :questionnaire="questionnaire"
                :level="1"
                :panelState="panelState"
                @panelsUpdate="panelsUpdate"
              />
            </div>
          </div>
          <br />
          <div v-if="questionnaire && files">
            <h1 class="font-weight-bold mt-0 mb-6">Bilag</h1>
            <v-card class="bg-white">
              <v-card-text>
                <FileListDisplay
                  :questionnaire="questionnaire"
                  :files="files"
                />
              </v-card-text>
            </v-card>
          </div>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted, computed } from 'vue';
import { FileListDisplay, FileDisplay, getTenderFiles, Tender, useStore, DeepQuestionnaire, TemplateQuestionPanelState, downloadFile, useBackend, ReadOnlyTemplateQuestion } from '@dims/components';

const { tender } = defineProps<{ tender: Tender }>();

const store = useStore();
const backend = useBackend();
const questionnaire = ref<DeepQuestionnaire | null>(null);
const files = ref<FileDisplay[] | null>(null);
const isLoading = ref(false);
const isDownloading = ref(false);
const panelState = ref<TemplateQuestionPanelState>(TemplateQuestionPanelState.INIT);

onMounted(async () => {
  isLoading.value = true;
  await fetchQuestionnaire();
  if (questionnaire.value) {
    files.value = await getTenderFiles(questionnaire.value.id);
  }
  isLoading.value = false;
});

async function fetchQuestionnaire() {
  try {
    const deepQuestionnaire = await store.backend.deepQuestionnaireService
      .previewQuestionnaire(
        tender.id,
        'specification',
      );
    questionnaire.value = deepQuestionnaire;
  } catch (e) {
    console.error(e);
  }
}

const specificationTabs = computed(() => questionnaire.value?.questions ?? []);

function expandingPanel(value: boolean) {
  panelState.value = value
    ? TemplateQuestionPanelState.EXPANDED
    : TemplateQuestionPanelState.COLLAPSED;
}

function panelsUpdate() {
  panelState.value = TemplateQuestionPanelState.CHANGED;
}

async function downloadPdf() {
  if (questionnaire.value) {
    isDownloading.value = true;
    try {
      const pdfBlob = await backend.pdfService.specificationAsPdf(tender);
      downloadFile(pdfBlob, `Kravspecifikation-${tender.friendlyId}.pdf`);
    } catch (e) {
      console.error(e);
      store.setSnackbarText('Kan ikke hente fil');
    } finally {
      isDownloading.value = false;
    }
  }
}

</script>

<style scoped>

.scroll {
  overflow-y: scroll;
}

.header {
  margin: 16px;
  font-size: 0.875rem;
}

.title-text {
  font-size: 1.5em;
  font-weight: 700;
}

</style>
